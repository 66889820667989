import React from 'react'

import GatsbyHead from '@components/Layout/GatsbyHead'
import Layout from '@components/Layout/Layout'
import SEO from '@components/Layout/SEO'

const Calculator = () => {
	React.useEffect(() => {
		const jQueryScript = document.createElement('script')
		const iframeScript = document.createElement('script')

		jQueryScript.src = 'https://code.jquery.com/jquery-3.6.1.min.js'
		iframeScript.text = `var AcTarget = "financefinity"; var AcInjectParentScript = document.createElement('script');
		document.body.appendChild(AcInjectParentScript); var acRng = Math.floor(Math.random() * 10000000) + 1;
		AcInjectParentScript.onload = function () {}; AcInjectParentScript.src =
		"https://iframe.app.autoconvert.co.uk/js/iframe/"+AcTarget+"/parent-comms.js?ver=" + acRng;`

		if (document) {
			document.body.appendChild(jQueryScript)
			document.body.appendChild(iframeScript)
		}
	})

	return (
		<Layout type="full">
			<div id="iframe-container"></div>
		</Layout>
	)
}

export default Calculator

export function Head() {
	return (
		<>
			<GatsbyHead />
			<SEO data={{ title: 'Apply' }} />
		</>
	)
}
